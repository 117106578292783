@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-rectangle {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 73%);
  }

  @keyframes slideIn {
    from {
      transform: translateX(-600px);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }

    90% {
      transform: translateX(900px);
    }

    100% {
      display: none;
    }
  }

  .slideIn {
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
  }

  .slideOut {
    animation-name: slideOut;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
  }

  .open {
    transform: rotate(90deg);
    transform: translateY(0px);
  }

  .hamburger {
    cursor: pointer;
    transition: all 0.25s;
    position: relative;
  }

  .ham-top,
  .ham-mid,
  .ham-buttom {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    transform: rotate(0);
    transition: all 0.5s;
  }

  .ham-mid {
    transform: translateY(7px);
  }

  .ham-buttom {
    transform: translateY(14px);
  }

  .open .ham-top {
    transform: rotate(45deg) translateY(6px) translate(6px);
  }

  .open .ham-mid {
    display: none;
  }

  .open .ham-buttom {
    width: 24px;
    transform: rotate(-45deg) translateY(6px) translate(-6px);
  }

  .nav::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.175rem;
    background-color: #1a237e;
    left: 0;
    top: 3rem;
    transform: scale(0, 1);
    transition: transform 0.3s ease;
  }

  .nav:hover::after {
    transform: scale(1, 1);
    top: 3rem;
  }

  /* Hide the scrollbar by default */
  .hide-scrollbar::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 10px;
  }

  /* Show the scrollbar when hovering */
  .hide-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.5); /* Adjust the color as needed */
  }

  /* Style the scrollbar thumb */
  .hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }
}
